import CallRoundedIcon from '@mui/icons-material/CallRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { Box, Grid, Snackbar, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import constants from '../constants';
import CopyTooltip from './CopyTooltip';
import Section from './Section';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CopyToClipBoardButton from './CopyToClipBoardButton';

function ContactSection() {
  const { t } = useTranslation();

  return (
    <Section
      id="contact-section"
      title={t('contact.withMe')}
      description={t('contact.questions') || undefined}>
      <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
        <ContactItem
          icon={<EmailRoundedIcon fontSize="large" color="secondary" />}
          title={t('contact.email')}
          description={constants.email}
          tooltipExtraAction={<EmailRoundedIcon />}
        />
        <ContactItem
          icon={<CallRoundedIcon fontSize="large" color="secondary" />}
          title={t('contact.phone')}
          description={constants.phoneNumber}
          tooltipExtraAction={<CallRoundedIcon />}
        />
        {/* <ContactItem
          icon={<PlaceRoundedIcon fontSize="large" color="secondary" />}
          title="Address"
          description={constants.location}
          tooltipExtraAction={<PlaceRoundedIcon />}
        /> */}
      </Grid>
    </Section>
  );
}

export default ContactSection;

const circleRadius = 30;

type ContactItemProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  tooltipExtraAction: React.ReactNode;
};
function ContactItem({ icon, title, description, tooltipExtraAction }: ContactItemProps) {
  const currentTheme = useTheme();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: `${circleRadius * 2}px`,
              height: `${circleRadius * 2}px`,
              marginBottom: 1,
            }}>
            {icon}
          </Box>
        </Box>

        <Typography
          variant="h6"
          color={currentTheme.palette.grey[800]}
          textAlign="center"
          sx={{ marginBottom: 0 }}>
          {title}
        </Typography>

        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant="body1" textTransform="lowercase" textAlign="center">
            {description}
          </Typography>

          <CopyToClipBoardButton text={description} />
        </Stack>
      </Box>
    </Grid>
  );
}
