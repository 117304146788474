import { SVGProps } from 'react';
import useProportionalSvg, { ProportionalSvgProps } from '../../hooks/useProportionalSvg';

const initWidth = 747.72;
const initHeight = 409.92;

type Props = {
  width: number | string;
  height: number | string;
};

export default CncManLight;
function CncManLight(props: Props) {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 197.83 108.46"
      xmlns="http://www.w3.org/2000/svg"
      role="img">
      <title>{'cncWithMan_Light'}</title>
      <defs>
        <style>
          {
            '\n      .cls-1 {\n        fill: #3f4567;\n      }\n\n      .cls-1, .cls-10, .cls-11, .cls-12, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {\n        fill-rule: evenodd;\n      }\n\n      .cls-14, .cls-2, .cls-8 {\n        stroke: #807f8f;\n      }\n\n      .cls-2 {\n        stroke-linejoin: round;\n      }\n\n      .cls-10, .cls-14, .cls-2, .cls-3, .cls-8 {\n        stroke-width: 1px;\n      }\n\n      .cls-3, .cls-7 {\n        fill: #fff;\n      }\n\n      .cls-3 {\n        stroke: #000;\n      }\n\n      .cls-13, .cls-4 {\n        fill: #f0a500;\n      }\n\n      .cls-5 {\n        fill: #d7d7d7;\n      }\n\n      .cls-15, .cls-6 {\n        fill: #f2f2f2;\n      }\n\n      .cls-10, .cls-14, .cls-8 {\n        fill: none;\n      }\n\n      .cls-9 {\n        fill: #4285f4;\n      }\n\n      .cls-10 {\n        stroke: #3f4567;\n      }\n\n      .cls-11 {\n        fill: #c28500;\n      }\n\n      .cls-12 {\n        fill: #c1c1c1;\n      }\n\n      .cls-16 {\n        fill: #e6e6e6;\n      }\n\n      .cls-17 {\n        fill: #f9ad81;\n      }\n\n      .cls-18 {\n        fill: #362f2d;\n      }\n\n      .cls-19 {\n        fill: #a67c52;\n      }\n\n      .cls-20 {\n        fill: #303030;\n      }\n\n      .cls-21 {\n        fill: #4b4b4b;\n      }\n    '
          }
        </style>
      </defs>
      <g transform="translate(-4.1822 -5.4543)">
        <g>
          <g>
            <g>
              <path
                d="m144.31 89.75-0.26458 14.288 26.448-12.161v-11.477z"
                fill="#c28500"
                stroke="#c28500"
                strokeLinejoin="bevel"
                strokeWidth=".26458px"
              />
              <path
                d="m33.712 81.813v11.906l110.33 10.319 0.26458-14.288-5.0271-0.26458v3.1541l-1.0583 0.55002-76.729-5.8208v-3.7042l-27.781-1.8521"
                fill="#f0a500"
              />
              <path
                d="m33.712 19.107 27.781-0.78077v65.339l-27.781-1.8521z"
                fill="#f2f2f2"
                style={{
                  paintOrder: 'normal',
                }}
              />
              <path
                d="m33.712 19.107 2.6458-4.2333 25.135-0.79375v4.2463z"
                fill="#fff"
                style={{
                  paintOrder: 'normal',
                }}
              />
              <path
                d="m144.31 89.75 33.338-11.906v-59.796l-31.745-6.6146-1.5926 3.9687z"
                fill="#d7d7d7"
              />
              <path d="m144.31 15.403h-5.0271l1.5875-3.9687h5.0322z" fill="#fff" />
              <path d="m144.31 89.75-5.0271-0.26458v-74.083h5.0271z" fill="#f2f2f2" />
              <path
                d="m138.22 93.19-76.729-5.8208v-78.581l76.729-3.175z"
                fill="#f2f2f2"
                stroke="#727696"
                strokeLinejoin="bevel"
                strokeWidth={0.3175}
              />
              <path
                d="m138.22 93.19 1.0583-0.55002v-77.237l1.5875-3.9687h5.0322l13.753 2.8537v-4.1766l-21.431-4.4979z"
                fill="#d7d7d7"
                stroke="#727696"
                strokeLinejoin="bevel"
                strokeWidth={0.3175}
              />
            </g>
            <path
              d="m33.712 93.719v-74.612l2.6458-4.2333 25.135-0.79375v-5.2917l76.729-3.175 21.431 4.4979v4.1766l17.992 3.7609v59.796l-7.154 2.5558v11.477l-26.448 12.161z"
              fill="none"
              stroke="#727696"
              strokeLinejoin="bevel"
              strokeWidth={0.3175}
            />
          </g>
          <g>
            <path d="m151.45 22.811 15.875 2.1167v48.419l-15.875 4.4979z" fill="#c1c1c1" />
            <path
              d="m68.901 22.546 35.19-1.0583v52.917l-25.929-0.79375-9.2604-11.377z"
              fill="#4285f4"
              stroke="#727696"
              strokeWidth={0.39688}
            />
            <path d="m107.27 18.778 28.575-0.72972 0.045 27.318-28.575-0.11033z" fill="#fff" />
            <g>
              <path d="m110.71 31.355v-8.5439l10.583-0.18732v8.5439z" fill="#4285f4" />
              <g fill="#f2f2f2">
                <path d="m125.52 31.13v-4.3505l7.1438-0.14737v4.3503z" />
                <path d="m125.52 37.892v-3.175h7.1438v3.175z" />
                <ellipse
                  cx={121.46}
                  cy={40.516}
                  rx={2.149}
                  ry={2.2191}
                  style={{
                    paintOrder: 'normal',
                  }}
                />
              </g>
              <ellipse
                cx={112.85}
                cy={39.511}
                rx={3.3244}
                ry={3.605}
                fill="#f0a500"
                style={{
                  paintOrder: 'normal',
                }}
              />
            </g>
            <g fill="none" stroke="#727696" strokeWidth={0.39688}>
              <g>
                <path d="m111.71 30.355v-8.5439l10.583-0.18733v8.5439z" />
                <path d="m126.52 30.13v-4.3505l7.1436-0.14737v4.3503z" />
                <path d="m126.52 36.892v-3.175h7.1436v3.175z" />
              </g>
              <ellipse
                cx={122.46}
                cy={39.517}
                rx={2.1489}
                ry={2.2191}
                strokeLinejoin="bevel"
                style={{
                  paintOrder: 'normal',
                }}
              />
              <ellipse
                cx={113.85}
                cy={38.511}
                rx={3.3243}
                ry={3.605}
                strokeLinejoin="bevel"
                style={{
                  paintOrder: 'normal',
                }}
              />
            </g>
          </g>
          <g
            fill="none"
            stroke="#727696"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.3175}>
            <path d="m170.49 91.877 31.37 2.3647z" />
            <path d="m170.49 91.877 9.1318-4.1729z" />
            <path d="m33.712 81.813-29.373-2.1113z" />
          </g>
        </g>
        <ellipse cx={92.869} cy={107.95} rx={22.433} ry={5.9626} fill="#e6e6e6" />
        <ellipse
          cx={94.225}
          cy={106.59}
          rx={22.433}
          ry={5.9626}
          fill="none"
          stroke="#727696"
          strokeWidth={0.39688}
        />
        <g fill="#f9ad81">
          <ellipse transform="rotate(-5.5091)" cx={78.312} cy={75.346} rx={1.0372} ry={2.8335} />
          <ellipse
            transform="matrix(.9714 -.23743 .2333 .9724 0 0)"
            cx={98.298}
            cy={69.517}
            rx={1.0241}
            ry={2.4892}
          />
          <path d="m93.393 31.236 1.0569-1.2762 2.119-0.56144 1.6825 0.07667 1.1207 1.0803 0.47923 1.2746 0.08785 1.9122 0.09052 0.8194-0.17823 0.91151-0.44458 0.82046-0.53508 0.2742-0.62028 1.7304-0.62027 0.54547-3.711-0.45256-0.08785-0.54627-0.08785-2.0831-0.79065-0.63146-0.08785-0.90512 0.26355-0.90512 0.26355-0.09184z" />
        </g>
        <g>
          <path
            d="m92.251 39.473 0.96635-1.5384 1.6718-0.0011 2.0339 0.17996 1.5946 0.27207v0.72782l-0.17836 0.90938z"
            fill="#fff"
          />
          <path
            d="m93.48 30.056 1.7623-1.0116 1.6798-0.19646 1.5946 0.07694 1.4216 1.0806 0.53509 1.6361-0.35673 2.3706-0.53508 0.36684-0.71079 1.5496-0.70812 0.5476-0.88649 0.36524-2.2122 0.0037-0.79331-0.72357-0.5271-1.0856-0.3514-1.8081-0.1757-1.8116z"
            fill="#362f2d"
          />
          <path
            d="m97.897 104.48 4.3606-0.1994 1.1634 0.52471 0.35938 0.75231-2.4145 2.866-3.1147-0.012-0.79864-1.4131z"
            fill="#a67c52"
          />
          <path
            d="m90.584 107.94-3.0535-0.3703-2.3453-3.112 0.34608-0.69881 1.1287-0.38334 4.2754 0.702 0.43659 2.5556z"
            fill="#a67c52"
          />
          <path
            d="m87.182 64.593 7.3554 1.8036 8.2579-0.70786-0.53775 18.15 0.17836 6.8539-1.2485 14.532-3.9107-0.23213-1.1599-14.067-1.5795-13.108-1.4961 8.9996-1.0542 13.158-0.7028 4.2993-3.8414-0.22682-0.46994-6.0368-0.22753-7.5057z"
            fill="#303030"
          />
          <path
            d="m92.163 38.659 6.7991 0.2726 5.7156 2.8365 5.1379 5.5271 1.1793 1.0207 0.18102-2.3903 1.9114 0.01199 0.91045 5.4475-0.36471 2.0232-1.8209 0.6208-5.697-3.4658-1.7969-1.3904-1.2539 10.949 0.35673 7.2247-7.4726 0.35406-0.97167-1.2949-1.1447 1.2398-7.0866-1.4442 0.87051-6.2839 0.1757-10.718-1.2193 9.708-0.08519 6.3891-3.0322 0.73475-0.51912-7.4579 0.68949-9.8608 2.167-6.3654z"
            fill="#4b4b4b"
          />
        </g>
      </g>
    </svg>
  );
}
