import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/translation.json';
import lv from './lv/translation.json';
import ru from './ru/translation.json';

export const languages = {
  en: {
    name: 'EN',
  },
  lv: {
    name: 'LV',
  },
  ru: {
    name: 'RU',
  },
};

export type TLanguage = keyof typeof languages;

export const languageKeys = Object.keys(languages) as TLanguage[];

export const defaultNS = 'translation';

export const resources = {
  en: {
    translation: en,
  },
  lv: {
    translation: lv,
  },
  ru: {
    translation: ru,
  },
};

i18next.use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: false,
  resources,
  defaultNS,
});

export default i18next;
