import { SVGProps } from 'react';

const SolidMaterialColorfulIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 16.933 16.933"
    role="img"
    {...props}>
    <path fill="#fff" d="M3.906 3.55.265 8.143v2.325l3.298 1.339 3.641-4.594V4.889z" />
    <path
      d="M3.871 3.287a.265.265 0 0 0-.173.099l-3.63 4.58-.01.012a.265.265 0 0 0-.004.005.265.265 0 0 0-.028.045.265.265 0 0 0-.008.016.265.265 0 0 0-.017.117v2.307a.265.265 0 0 0 .164.244l3.286 1.335a.265.265 0 0 0 .008.003l.005.002a.265.265 0 0 0 .002 0 .265.265 0 0 0 .098.02.265.265 0 0 0 .216-.115l3.63-4.579a.265.265 0 0 0 .058-.166V4.913a.265.265 0 0 0-.1-.232.265.265 0 0 0-.06-.035.265.265 0 0 0-.005-.002l-3.298-1.34a.265.265 0 0 0-.134-.017zm.118.583 2.788 1.132-3.298 4.161L.691 8.03zm2.95 1.78v1.47l-3.112 3.928V9.575zM.528 8.535l2.77 1.125v1.754l-2.77-1.126z"
      color="#000"
    />
    <path
      fill="#aaa"
      d="m8.638 4.338-3.64 4.593v2.325l3.297 1.339V10.27v2.325l3.642-4.594V5.677z"
    />
    <path
      d="M8.604 4.076a.265.265 0 0 0-.174.097l-3.629 4.58a.265.265 0 0 0-.008.01l-.003.004a.265.265 0 0 0 0 .001.265.265 0 0 0-.039.063.265.265 0 0 0-.018.137v2.287a.265.265 0 0 0 .164.246l3.3 1.34a.265.265 0 0 0 .017.005.265.265 0 0 0 .023.006.265.265 0 0 0 .049.007.265.265 0 0 0 .01 0 .265.265 0 0 0 .01-.001.265.265 0 0 0 .047-.006.265.265 0 0 0 .025-.007.265.265 0 0 0 .027-.01.265.265 0 0 0 .004-.002.265.265 0 0 0 .033-.02.265.265 0 0 0 .02-.014.265.265 0 0 0 .035-.034.265.265 0 0 0 .006-.006l3.64-4.593a.265.265 0 0 0 .057-.165V5.678a.265.265 0 0 0 0-.002.265.265 0 0 0-.1-.206.265.265 0 0 0-.059-.034.265.265 0 0 0-.005-.003l-3.299-1.34a.265.265 0 0 0-.133-.017zm.117.582 2.79 1.132-3.298 4.161-2.79-1.132zm2.95 1.78V7.91L8.56 11.836v-1.473zm-6.41 2.886 2.769 1.123v1.754l-2.77-1.123z"
      color="#000"
    />
    <path fill="#f0a500" d="M13.37 5.127 9.73 9.72v2.325l3.297 1.339 3.641-4.594V6.466z" />
    <path
      d="M13.336 4.865a.265.265 0 0 0-.173.097l-3.64 4.594a.265.265 0 0 0-.01.015.265.265 0 0 0-.013.02.265.265 0 0 0-.011.022.265.265 0 0 0-.004.007.265.265 0 0 0-.013.042.265.265 0 0 0-.006.055.265.265 0 0 0 0 .003v2.324a.265.265 0 0 0 .164.246l3.286 1.333a.265.265 0 0 0 .011.005h.002a.265.265 0 0 0 .063.017.265.265 0 0 0 .002 0 .265.265 0 0 0 .034.003.265.265 0 0 0 .025-.002.265.265 0 0 0 .013-.001.265.265 0 0 0 .052-.014.265.265 0 0 0 .016-.006.265.265 0 0 0 .044-.025.265.265 0 0 0 .016-.012.265.265 0 0 0 .034-.033.265.265 0 0 0 .007-.007l3.641-4.593a.265.265 0 0 0 .057-.165V6.466a.265.265 0 0 0-.003-.037.265.265 0 0 0 0-.003.265.265 0 0 0-.003-.015.265.265 0 0 0 0-.005.265.265 0 0 0-.005-.013.265.265 0 0 0-.001-.006.265.265 0 0 0 0-.002.265.265 0 0 0-.007-.016.265.265 0 0 0-.004-.01.265.265 0 0 0-.004-.008.265.265 0 0 0-.138-.131l-3.299-1.338a.265.265 0 0 0-.133-.017zm.118.582 2.789 1.132-3.298 4.16-2.79-1.13zm2.949 1.78v1.471l-3.111 3.928v-1.474zm-6.41 2.886 2.77 1.124v1.753l-2.77-1.123z"
      color="#000"
    />
  </svg>
);
export default SolidMaterialColorfulIcon;
