import { useMediaQuery } from '@mui/material';
import { Breakpoints, createTheme } from '@mui/material/styles';
import createMixins, { Mixins, MixinsOptions } from '@mui/material/styles/createMixins';
import { CSSProperties } from '@mui/styled-engine';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F0A500',
      dark: '#E29900',
    },
    secondary: {
      main: '#4285F4',
    },
    neutral: {
      main: '#616161',
    },
    light: {
      main: '#FFFFFF',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#fff',
        },
      },
    },
  },
  toolbars: {
    top: {
      height: '36px',
    },
  },
});

const mixins: Partial<Mixins> = {
  toolbars: {
    top: {
      height: '36px',

      [theme.breakpoints.up('sm')]: {
        height: '0px',
      },
    },
    bottom: {
      height: '50px',
    },
    topAndBottom: {
      height: 50,

      [theme.breakpoints.up('sm')]: {
        height: 86,
      },
    },
  },
};

theme.mixins = {
  ...theme.mixins,
  ...mixins,
};

export default theme;

declare module '@mui/material/styles' {
  // fix the type error when referencing the Theme object in your styled component
  interface Theme {
    toolbars: {
      top: CSSProperties;
    };
  }
  // fix the type error when calling `createTheme()` with a custom theme option
  interface ThemeOptions {
    toolbars: {
      top: CSSProperties;
    };
  }

  interface Palette {
    neutral: Palette['primary'];
    light: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    light: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface Mixins {
    toolbars: {
      topAndBottom: CSSProperties;
      top: CSSProperties;
      bottom: CSSProperties;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    light: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true;
    light: true;
  }
}

declare module '@mui/material/MenuItem' {
  interface MenuItemPropsColorOverrides {
    neutral: true;
    light: true;
  }
}
