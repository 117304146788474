import { TFuncKey } from 'i18next';

const email = 'vbolhov@inbox.lv';
const phoneNumber = '+371 28 303 383';
const location = 'Map location';

const sections: Array<{ id: string; titleKey: TFuncKey }> = [
  {
    id: 'back-to-top-anchor',
    titleKey: 'home',
  },
  {
    id: 'about-section',
    titleKey: 'aboutMe',
  },
  {
    id: 'services-section',
    titleKey: 'services.title',
  },
  {
    id: 'portfolio-section',
    titleKey: 'portfolio',
  },
  {
    id: 'contact-section',
    titleKey: 'contact.title',
  },
];

const constants = {
  email,
  phoneNumber,
  location,
  sections,
};

export default constants;
