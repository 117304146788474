import { useMemo } from 'react';

export default useProportionalSvg;
function useProportionalSvg(
  fixed: 'width' | 'height',
  size: number,
  initWidth: number,
  initHeight: number
) {
  const ratio = useMemo(() => {
    return {
      width: initWidth / initHeight,
      height: initHeight / initWidth,
    };
  }, [initWidth, initHeight]);

  return {
    width: fixed === 'width' ? size : size * ratio.width,
    height: fixed === 'height' ? size : size * ratio.height,
  };
}

export type ProportionalSvgProps = {
  fixed: 'width' | 'height';
  size: number;
};
