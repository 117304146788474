import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  text: string;
};

const CopyToClipBoardButton = (props: Props) => {
  const { text } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClose = (event: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function copyToClipBoard(copyText: string) {
    setOpen(true);
    navigator.clipboard.writeText(copyText);
  }

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseRoundedIcon fontSize="small" />
    </IconButton>
  );
  return (
    <Fragment>
      <IconButton onClick={() => copyToClipBoard(text)}>
        <ContentCopyOutlinedIcon fontSize="small" />
      </IconButton>

      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        message={t('copied')}
        action={action}
      />
    </Fragment>
  );
};

export default CopyToClipBoardButton;
