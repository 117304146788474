import MenuIcon from '@mui/icons-material/Menu';
import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ViktorCncHorizontal from '../../assets/illustrations/ViktorCncHorizontal';
import constants from '../../constants';
import CopyToClipBoardButton from '../CopyToClipBoardButton';
import CopyTooltip from '../CopyTooltip';
import LanguageSelect from '../LanguageSelect';
import Drawer from './Drawer';
import ElevationScroll from './ElevationScroll';
import ScrollTop from './ScrollTop';

const scrollTopBarAnchorId = 'back-to-top-anchor';

export default TopBar;
function TopBar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const currentTheme = useTheme();
  const theme = useTheme();
  const { t } = useTranslation();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const scrollToSection = (id: string) => {
    const anchor = document.getElementById(id);

    if (anchor) {
      window.scrollTo({
        top: anchor.offsetTop - (upSm ? 86 : 50),
      });
    }
  };

  return (
    <>
      <ElevationScroll>
        <AppBar>
          <Container sx={{ paddingX: { xs: 0 } }}>
            <Toolbar
              sx={{
                display: { xs: 'none', md: 'flex' },
                minHeight: { xs: theme.mixins.toolbars.top.height },
                height: { xs: theme.mixins.toolbars.top.height },
              }}>
              <Stack direction="row" spacing={2} flexGrow={1}>
                <Box>
                  <Typography
                    color={currentTheme.palette.grey[600]}
                    variant="button"
                    textTransform="lowercase">
                    {constants.email}
                  </Typography>
                  <CopyToClipBoardButton text={constants.email} />
                </Box>

                <Box>
                  <Typography
                    color={currentTheme.palette.grey[600]}
                    variant="button"
                    textTransform="lowercase">
                    {constants.phoneNumber}
                  </Typography>
                  <CopyToClipBoardButton text={constants.phoneNumber} />
                </Box>
              </Stack>

              <Box>
                <Box mr={-2}>
                  <LanguageSelect />
                </Box>

                {/* <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="neutral"
                  sx={{ display: { xs: 'none', md: 'inline-flex' }, marginRight: 2 }}>
                  <DarkModeOutlinedIcon />
                </IconButton> */}

                {/* <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="neutral">
                  <FacebookRoundedIcon />
                </IconButton>

                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="neutral">
                  <FacebookRoundedIcon />
                </IconButton>

                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="neutral">
                  <FacebookRoundedIcon />
                </IconButton> */}
              </Box>
            </Toolbar>

            <Toolbar
              sx={{
                minHeight: { xs: '50px' },
                height: { xs: '50px' },
              }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' }, color: '#000000' }}>
                <MenuIcon />
              </IconButton>

              <Box sx={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
                <ViktorCncHorizontal
                  fixed="height"
                  size={32}
                  primaryColor={currentTheme.palette.primary.main}
                  secondaryColor={currentTheme.palette.grey[900]}
                />
              </Box>

              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {constants.sections.map(section => {
                  const text = t(section.titleKey) as string;

                  return (
                    <Button
                      key={section.id}
                      disableElevation
                      variant="text"
                      color="neutral"
                      onClick={() => scrollToSection(section.id)}
                      sx={{ borderRadius: '100px' }}>
                      {text}
                    </Button>
                  );
                })}
              </Box>

              <Stack
                sx={{
                  display: { xs: 'none', sm: 'flex', md: 'none' },
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Stack direction="row" spacing={2} flexGrow={1} mr={2}>
                  <CopyTooltip
                    copyText={constants.email}
                    // titleRightNode={
                    //   <IconButton color="primary">
                    //     <EmailRoundedIcon />
                    //   </IconButton>
                    // }
                  >
                    <Typography
                      color={currentTheme.palette.grey[600]}
                      variant="button"
                      textTransform="lowercase">
                      {constants.email}
                    </Typography>
                  </CopyTooltip>

                  <CopyTooltip
                    copyText={constants.phoneNumber}
                    // titleRightNode={
                    //   <IconButton color="primary">
                    //     <CallRoundedIcon />
                    //   </IconButton>
                    // }
                  >
                    <Typography
                      color={currentTheme.palette.grey[600]}
                      variant="button"
                      textTransform="lowercase">
                      {constants.phoneNumber}
                    </Typography>
                  </CopyTooltip>
                </Stack>

                <Box mr={-2}>
                  <LanguageSelect />
                </Box>

                {/* <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit">
                  <DarkModeOutlinedIcon />
                </IconButton> */}
              </Stack>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>

      <Box
        id={scrollTopBarAnchorId}
        sx={{ height: theme.mixins.toolbars.topAndBottom.height + 'px' }}
      />

      <Drawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

      <ScrollTop id={`#${scrollTopBarAnchorId}`} />
    </>
  );
}
