const imageGroups = {
  group_0: {
    item_1: {
      100: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_100.jpg') as string,
      328: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_328.jpg') as string,
      473: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_473.jpg') as string,
      594: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_594.jpg') as string,
      688: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_688.jpg') as string,
      764: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_764.jpg') as string,
      854: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_854.jpg') as string,
      938: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_938.jpg') as string,
      1013: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1013.jpg') as string,
      1084: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1084.jpg') as string,
      1162: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1162.jpg') as string,
      1413: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1413.jpg') as string,
      1478: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1478.jpg') as string,
      1553: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1553.jpg') as string,
      1599: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1599.jpg') as string,
      1600: require('./group-0_1/group-0_1_pfdvwp_c_scale,w_1600.jpg') as string,
    },
    item_2: {
      100: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_100.jpg') as string,
      359: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_359.jpg') as string,
      539: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_539.jpg') as string,
      690: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_690.jpg') as string,
      790: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_790.jpg') as string,
      967: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_967.jpg') as string,
      978: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_978.jpg') as string,
      1193: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1193.jpg') as string,
      1294: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1294.jpg') as string,
      1393: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1393.jpg') as string,
      1467: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1467.jpg') as string,
      1493: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1493.jpg') as string,
      1609: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1609.jpg') as string,
      1689: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1689.jpg') as string,
      1780: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1780.jpg') as string,
      1852: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1852.jpg') as string,
      1938: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1938.jpg') as string,
      1998: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_1998.jpg') as string,
      2000: require('./group-0_2/group-0_2_fnkxnj_c_scale,w_2000.jpg') as string,
    },
    item_3: {
      100: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_100.jpg') as string,
      358: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_358.jpg') as string,
      529: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_529.jpg') as string,
      671: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_671.jpg') as string,
      773: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_773.jpg') as string,
      889: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_889.jpg') as string,
      990: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_990.jpg') as string,
      1089: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1089.jpg') as string,
      1183: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1183.jpg') as string,
      1274: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1274.jpg') as string,
      1365: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1365.jpg') as string,
      1507: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1507.jpg') as string,
      1599: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1599.jpg') as string,
      1683: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1683.jpg') as string,
      1758: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1758.jpg') as string,
      1844: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1844.jpg') as string,
      1931: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1931.jpg') as string,
      1993: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_1993.jpg') as string,
      2000: require('./group-0_3/group-0_3_uq4ew9_c_scale,w_2000.jpg') as string,
    },
  },
  group_1: {
    item_1: {
      100: require('./group-1_1/group-1_1_mjjbte_c_scale,w_100.jpg') as string,
      303: require('./group-1_1/group-1_1_mjjbte_c_scale,w_303.jpg') as string,
      507: require('./group-1_1/group-1_1_mjjbte_c_scale,w_507.jpg') as string,
      627: require('./group-1_1/group-1_1_mjjbte_c_scale,w_627.jpg') as string,
      754: require('./group-1_1/group-1_1_mjjbte_c_scale,w_754.jpg') as string,
      863: require('./group-1_1/group-1_1_mjjbte_c_scale,w_863.jpg') as string,
      968: require('./group-1_1/group-1_1_mjjbte_c_scale,w_968.jpg') as string,
      1074: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1074.jpg') as string,
      1167: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1167.jpg') as string,
      1282: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1282.jpg') as string,
      1350: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1350.jpg') as string,
      1436: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1436.jpg') as string,
      1533: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1533.jpg') as string,
      1662: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1662.jpg') as string,
      1682: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1682.jpg') as string,
      1762: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1762.jpg') as string,
      1838: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1838.jpg') as string,
      1918: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1918.jpg') as string,
      1958: require('./group-1_1/group-1_1_mjjbte_c_scale,w_1958.jpg') as string,
      2000: require('./group-1_1/group-1_1_mjjbte_c_scale,w_2000.jpg') as string,
    },
  },
  group_2: {
    item_1: {
      100: require('./group-2_1/group-2_1_kwnucj_c_scale,w_100.jpg') as string,
      415: require('./group-2_1/group-2_1_kwnucj_c_scale,w_415.jpg') as string,
      616: require('./group-2_1/group-2_1_kwnucj_c_scale,w_616.jpg') as string,
      782: require('./group-2_1/group-2_1_kwnucj_c_scale,w_782.jpg') as string,
      946: require('./group-2_1/group-2_1_kwnucj_c_scale,w_946.jpg') as string,
      1062: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1062.jpg') as string,
      1178: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1178.jpg') as string,
      1273: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1273.jpg') as string,
      1368: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1368.jpg') as string,
      1452: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1452.jpg') as string,
      1534: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1534.jpg') as string,
      1604: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1604.jpg') as string,
      1677: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1677.jpg') as string,
      1748: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1748.jpg') as string,
      1821: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1821.jpg') as string,
      1884: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1884.jpg') as string,
      1949: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1949.jpg') as string,
      1998: require('./group-2_1/group-2_1_kwnucj_c_scale,w_1998.jpg') as string,
      2000: require('./group-2_1/group-2_1_kwnucj_c_scale,w_2000.jpg') as string,
    },
  },
  group_5: {
    item_1: {
      100: require('./group-5_1/group-5_1_gcrf61_c_scale,w_100.jpg') as string,
      233: require('./group-5_1/group-5_1_gcrf61_c_scale,w_233.jpg') as string,
      330: require('./group-5_1/group-5_1_gcrf61_c_scale,w_330.jpg') as string,
      413: require('./group-5_1/group-5_1_gcrf61_c_scale,w_413.jpg') as string,
      478: require('./group-5_1/group-5_1_gcrf61_c_scale,w_478.jpg') as string,
      541: require('./group-5_1/group-5_1_gcrf61_c_scale,w_541.jpg') as string,
      601: require('./group-5_1/group-5_1_gcrf61_c_scale,w_601.jpg') as string,
      661: require('./group-5_1/group-5_1_gcrf61_c_scale,w_661.jpg') as string,
      715: require('./group-5_1/group-5_1_gcrf61_c_scale,w_715.jpg') as string,
      772: require('./group-5_1/group-5_1_gcrf61_c_scale,w_772.jpg') as string,
      820: require('./group-5_1/group-5_1_gcrf61_c_scale,w_820.jpg') as string,
      869: require('./group-5_1/group-5_1_gcrf61_c_scale,w_869.jpg') as string,
      917: require('./group-5_1/group-5_1_gcrf61_c_scale,w_917.jpg') as string,
      963: require('./group-5_1/group-5_1_gcrf61_c_scale,w_963.jpg') as string,
      1013: require('./group-5_1/group-5_1_gcrf61_c_scale,w_1013.jpg') as string,
      1053: require('./group-5_1/group-5_1_gcrf61_c_scale,w_1053.jpg') as string,
      1097: require('./group-5_1/group-5_1_gcrf61_c_scale,w_1097.jpg') as string,
      1142: require('./group-5_1/group-5_1_gcrf61_c_scale,w_1142.jpg') as string,
      1203: require('./group-5_1/group-5_1_gcrf61_c_scale,w_1203.jpg') as string,
      1786: require('./group-5_1/group-5_1_gcrf61_c_scale,w_1786.jpg') as string,
    },
  },
  group_7: {
    item_1: {
      100: require('./group-7_1/group-7_1_cwwcma_c_scale,w_100.jpg') as string,
      369: require('./group-7_1/group-7_1_cwwcma_c_scale,w_369.jpg') as string,
      551: require('./group-7_1/group-7_1_cwwcma_c_scale,w_551.jpg') as string,
      689: require('./group-7_1/group-7_1_cwwcma_c_scale,w_689.jpg') as string,
      835: require('./group-7_1/group-7_1_cwwcma_c_scale,w_835.jpg') as string,
      959: require('./group-7_1/group-7_1_cwwcma_c_scale,w_959.jpg') as string,
      1081: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1081.jpg') as string,
      1203: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1203.jpg') as string,
      1323: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1323.jpg') as string,
      1437: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1437.jpg') as string,
      1450: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1450.jpg') as string,
      1455: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1455.jpg') as string,
      1548: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1548.jpg') as string,
      1635: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1635.jpg') as string,
      1721: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1721.jpg') as string,
      1757: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1757.jpg') as string,
      1805: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1805.jpg') as string,
      1883: require('./group-7_1/group-7_1_cwwcma_c_scale,w_1883.jpg') as string,
      2000: require('./group-7_1/group-7_1_cwwcma_c_scale,w_2000.jpg') as string,
    },
  },
  group_9: {
    item_1: {
      100: require('./group-9_1/group-9_1_po61zd_c_scale,w_100.jpg') as string,
      270: require('./group-9_1/group-9_1_po61zd_c_scale,w_270.jpg') as string,
      401: require('./group-9_1/group-9_1_po61zd_c_scale,w_401.jpg') as string,
      405: require('./group-9_1/group-9_1_po61zd_c_scale,w_405.jpg') as string,
      640: require('./group-9_1/group-9_1_po61zd_c_scale,w_640.jpg') as string,
      644: require('./group-9_1/group-9_1_po61zd_c_scale,w_644.jpg') as string,
      713: require('./group-9_1/group-9_1_po61zd_c_scale,w_713.jpg') as string,
      754: require('./group-9_1/group-9_1_po61zd_c_scale,w_754.jpg') as string,
      809: require('./group-9_1/group-9_1_po61zd_c_scale,w_809.jpg') as string,
      837: require('./group-9_1/group-9_1_po61zd_c_scale,w_837.jpg') as string,
      850: require('./group-9_1/group-9_1_po61zd_c_scale,w_850.jpg') as string,
      899: require('./group-9_1/group-9_1_po61zd_c_scale,w_899.jpg') as string,
      940: require('./group-9_1/group-9_1_po61zd_c_scale,w_940.jpg') as string,
      962: require('./group-9_1/group-9_1_po61zd_c_scale,w_962.jpg') as string,
      985: require('./group-9_1/group-9_1_po61zd_c_scale,w_985.jpg') as string,
      1006: require('./group-9_1/group-9_1_po61zd_c_scale,w_1006.jpg') as string,
      1050: require('./group-9_1/group-9_1_po61zd_c_scale,w_1050.jpg') as string,
      1792: require('./group-9_1/group-9_1_po61zd_c_scale,w_1792.jpg') as string,
    },
  },
  group_11: {
    item_1: {
      100: require('./group-11_1/group-11_1_siwcre_c_scale,w_100.jpg') as string,
      323: require('./group-11_1/group-11_1_siwcre_c_scale,w_323.jpg') as string,
      471: require('./group-11_1/group-11_1_siwcre_c_scale,w_471.jpg') as string,
      633: require('./group-11_1/group-11_1_siwcre_c_scale,w_633.jpg') as string,
      758: require('./group-11_1/group-11_1_siwcre_c_scale,w_758.jpg') as string,
      854: require('./group-11_1/group-11_1_siwcre_c_scale,w_854.jpg') as string,
      977: require('./group-11_1/group-11_1_siwcre_c_scale,w_977.jpg') as string,
      1089: require('./group-11_1/group-11_1_siwcre_c_scale,w_1089.jpg') as string,
      1189: require('./group-11_1/group-11_1_siwcre_c_scale,w_1189.jpg') as string,
      1300: require('./group-11_1/group-11_1_siwcre_c_scale,w_1300.jpg') as string,
      1402: require('./group-11_1/group-11_1_siwcre_c_scale,w_1402.jpg') as string,
      1465: require('./group-11_1/group-11_1_siwcre_c_scale,w_1465.jpg') as string,
      1512: require('./group-11_1/group-11_1_siwcre_c_scale,w_1512.jpg') as string,
      1603: require('./group-11_1/group-11_1_siwcre_c_scale,w_1603.jpg') as string,
      1623: require('./group-11_1/group-11_1_siwcre_c_scale,w_1623.jpg') as string,
      1867: require('./group-11_1/group-11_1_siwcre_c_scale,w_1867.jpg') as string,
      1948: require('./group-11_1/group-11_1_siwcre_c_scale,w_1948.jpg') as string,
      1981: require('./group-11_1/group-11_1_siwcre_c_scale,w_1981.jpg') as string,
      2000: require('./group-11_1/group-11_1_siwcre_c_scale,w_2000.jpg') as string,
    },
  },
  group_16: {
    item_1: {
      100: require('./group-16_1/group-16_1_kquujd_c_scale,w_100.jpg') as string,
      456: require('./group-16_1/group-16_1_kquujd_c_scale,w_456.jpg') as string,
      604: require('./group-16_1/group-16_1_kquujd_c_scale,w_604.jpg') as string,
      687: require('./group-16_1/group-16_1_kquujd_c_scale,w_687.jpg') as string,
      770: require('./group-16_1/group-16_1_kquujd_c_scale,w_770.jpg') as string,
      840: require('./group-16_1/group-16_1_kquujd_c_scale,w_840.jpg') as string,
      947: require('./group-16_1/group-16_1_kquujd_c_scale,w_947.jpg') as string,
      1039: require('./group-16_1/group-16_1_kquujd_c_scale,w_1039.jpg') as string,
      1137: require('./group-16_1/group-16_1_kquujd_c_scale,w_1137.jpg') as string,
      1227: require('./group-16_1/group-16_1_kquujd_c_scale,w_1227.jpg') as string,
      1319: require('./group-16_1/group-16_1_kquujd_c_scale,w_1319.jpg') as string,
      1485: require('./group-16_1/group-16_1_kquujd_c_scale,w_1485.jpg') as string,
      1568: require('./group-16_1/group-16_1_kquujd_c_scale,w_1568.jpg') as string,
      1572: require('./group-16_1/group-16_1_kquujd_c_scale,w_1572.jpg') as string,
      1652: require('./group-16_1/group-16_1_kquujd_c_scale,w_1652.jpg') as string,
      1912: require('./group-16_1/group-16_1_kquujd_c_scale,w_1912.jpg') as string,
      1996: require('./group-16_1/group-16_1_kquujd_c_scale,w_1996.jpg') as string,
      2000: require('./group-16_1/group-16_1_kquujd_c_scale,w_2000.jpg') as string,
    },
  },
};

type Images = typeof imageGroups;

function toSrcSet<O extends Images, G extends keyof O, I extends keyof O[G]>(
  imageList: O,
  group: G,
  item: I
) {
  const obj = imageList[group][item] as object;

  return Object.keys(obj)
    .map(imageKey => {
      const key = imageKey as keyof typeof obj;
      return `${imageList[group][item][key]} ${imageKey}w`;
    })
    .join(', ');
}

const images = {
  list: imageGroups,
  toSrcSet,
};

export default images;
