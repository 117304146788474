import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MillingCncColorfulIcon from '../assets/icons/MillingCncColorfulIcon';
import Model3dColorfulIcon from '../assets/icons/Model3dColorfulIcon';
import SolidMaterialColorfulIcon from '../assets/icons/SolidMaterialColorfulIcon';
import TurningCncColorfulIcon from '../assets/icons/TurningCncColorfulIcon';
import Section, { SectionRef } from './Section';

const ServicesSection = React.forwardRef((props, ref: SectionRef) => {
  const { t } = useTranslation();

  return (
    <Section
      id="services-section"
      ref={ref}
      title={t('services.title')}
      description={t('services.description') || undefined}>
      <Grid container spacing={{ xs: '28px', sm: '8px' }} sx={{ justifyContent: 'center' }}>
        <ServiceItem
          icon={<TurningCncColorfulIcon width={40} height={40} />}
          title={t('services.list.turning.title')}
          description={t('services.list.turning.description')}
        />
        <ServiceItem
          icon={<MillingCncColorfulIcon width={40} height={40} />}
          title={t('services.list.milling.title')}
          description={t('services.list.milling.description')}
        />
        <ServiceItem
          icon={<Model3dColorfulIcon width={40} height={40} />}
          title={t('services.list.model.title')}
          description={t('services.list.model.description')}
        />
        <ServiceItem
          icon={<SolidMaterialColorfulIcon width={40} height={40} />}
          title={t('services.list.material.title')}
          description={t('services.list.material.description')}
        />
      </Grid>
    </Section>
  );
});
export default ServicesSection;

const circleRadius = 30;
const maxTextLength = 150;
const minTextToHide = 50;

type ServiceItemProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
};
function ServiceItem({ icon, title, description }: ServiceItemProps) {
  const currentTheme = useTheme();
  const { t } = useTranslation();

  const isTextCollapseNeeded =
    description.length > maxTextLength && description.length - maxTextLength > minTextToHide;

  const [isCollapsed, setIsCollapsed] = useState(isTextCollapseNeeded);

  useEffect(() => {
    setIsCollapsed(isTextCollapseNeeded);
  }, [isTextCollapseNeeded]);

  return (
    <Grid item xs={12} md={6}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: `${circleRadius * 2}px`,
              height: `${circleRadius * 2}px`,
              borderRadius: `${circleRadius}px`,
              backgroundColor: currentTheme.palette.primary.main,
            }}>
            {icon}
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: currentTheme.palette.grey[200],
            paddingTop: `${circleRadius}px`,
            marginTop: `${circleRadius}px`,
            borderRadius: '12px',
          }}>
          <Box sx={{ padding: 2, paddingTop: 1 }}>
            <Typography
              variant="h6"
              color={currentTheme.palette.grey[800]}
              textAlign="center"
              sx={{ marginBottom: 1 }}>
              {title}
            </Typography>

            <Typography variant="body1" color={currentTheme.palette.grey[600]} textAlign="center">
              {isCollapsed ? description.slice(0, maxTextLength - 1) + '...' : description}
            </Typography>

            {isTextCollapseNeeded && (
              <Stack sx={{ alignItems: 'flex-end', mt: 1 }}>
                <Button
                  variant="text"
                  onClick={() => setIsCollapsed(state => !state)}
                  sx={{ borderRadius: '100px' }}>
                  {t(isCollapsed ? 'action.showMore' : 'action.showLess')}
                </Button>
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
