import { Box, Container, Typography, useTheme } from '@mui/material';
import React, { Fragment, ReactNode } from 'react';

export type SectionRef =
  | ((instance: HTMLSpanElement | null) => void)
  | React.RefObject<HTMLSpanElement>
  | null
  | undefined;

type Props = {
  id?: string;
  title: string;
  description?: string | string[];
  maxWidth?: 'md' | 'lg' | 'xl';
  children: ReactNode;
};

const Section = React.forwardRef((props: Props, ref: SectionRef) => {
  const { id, title, description = undefined, maxWidth = 'md', children } = props;
  const currentTheme = useTheme();

  return (
    <Box ref={ref} sx={{ paddingY: 6 }} id={id}>
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        maxWidth={maxWidth}>
        <Typography
          variant="h4"
          mb={3}
          color={currentTheme.palette.grey[900]}
          fontWeight={currentTheme.typography.fontWeightBold}
          textAlign="center">
          {title}
        </Typography>

        <Description text={description} />

        {children}
      </Container>
    </Box>
  );
});

export default Section;

type DescriptionProps = {
  text?: string | string[];
};

const Description = (props: DescriptionProps) => {
  const { text } = props;
  const theme = useTheme();

  if (!text) return null;

  if (Array.isArray(text)) {
    return (
      <Fragment>
        {text.map((paragraph, index) => (
          <Typography
            key={index.toString()}
            variant="subtitle1"
            textAlign="center"
            mb={3}
            color={theme.palette.grey[600]}>
            {paragraph}
          </Typography>
        ))}
      </Fragment>
    );
  }

  return (
    <Typography variant="subtitle1" textAlign="center" mb={3} color={theme.palette.grey[600]}>
      {text}
    </Typography>
  );
};
