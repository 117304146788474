import useScrollTrigger from '@mui/material/useScrollTrigger';
import * as React from 'react';
import createSxStyleSheet from '../../utils/createSxStyleSheet';

interface Props {
  children: React.ReactElement;
}

export default ElevationScroll;
function ElevationScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: 0,
    sx: styles(trigger).topBarContainer,
  });
}

const styles = (trigger: boolean) =>
  createSxStyleSheet({
    topBarContainer: theme => ({
      boxShadow: trigger ? '0 4px 20px -10px rgba(0, 0, 0, 0.4);' : undefined,
    }),
  });
