import { Box, Grid, Typography, useTheme } from '@mui/material';
import Section from '../Section';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useTranslation } from 'react-i18next';

export default About;
function About() {
  const { t } = useTranslation();

  const descriptionParagraphs = t('about.paragraphs', { returnObjects: true });
  const descriptionList = t('about.list', { returnObjects: true });

  return (
    <Section id="about-section" title={t('aboutMe')} description={descriptionParagraphs}>
      <Box sx={{ marginX: { xs: 0, md: 9 } }}>
        <Grid container sx={{ justifyContent: { xs: 'center', md: 'space-between' } }} spacing={1}>
          {descriptionList.map((listRow, index) => (
            <CheckItem key={index.toString()} title={listRow} />
          ))}
        </Grid>
      </Box>
    </Section>
  );
}

type CheckItemProps = {
  title: string;
};
function CheckItem({ title }: CheckItemProps) {
  const currentTheme = useTheme();
  return (
    <Grid item xs={12} sm={8} md={6}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <CheckRoundedIcon sx={{ color: currentTheme.palette.secondary.main, mr: 2 }} />

        <Typography variant="body1" color={currentTheme.palette.grey[900]}>
          {title}
        </Typography>
      </Box>
    </Grid>
  );
}
