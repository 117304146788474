import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IconButton, SxProps, Theme, Tooltip, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type CopyTooltipProps = {
  copyText: string;
  placement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  titleRightNode?: ReactNode;
  children?: ReactElement<any, any>;
  sx?: SxProps<Theme> | undefined;
};

const CopyTooltip = (props: CopyTooltipProps) => {
  const { copyText, placement, titleRightNode, children, sx } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function copyToClipBoard() {
    setOpen(true);
    navigator.clipboard.writeText(copyText);
  }

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseRoundedIcon fontSize="small" />
    </IconButton>
  );

  if (!children) return null;

  return (
    <>
      <Tooltip
        sx={sx}
        placement={placement}
        title={
          <>
            <IconButton color="primary" onClick={copyToClipBoard}>
              <ContentCopyOutlinedIcon />
            </IconButton>

            {titleRightNode}
          </>
        }
        componentsProps={{
          tooltip: {
            sx: {
              boxShadow: '0 1px 3px 1px rgba(60, 64, 67, .15)',
              backgroundColor: theme.palette.common.white,
              borderRadius: '50px',
              padding: '4px',
            },
            style: {
              marginTop: '8px',
            },
          },
        }}>
        {children}
      </Tooltip>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={t('copied')}
        action={action}
      />
    </>
  );
};

export default CopyTooltip;
