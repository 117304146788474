import { SVGProps } from 'react';

const MillingCncColorfulIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 16.933 16.933"
    role="img"
    {...props}>
    <g strokeLinecap="round" strokeLinejoin="round" color="#000">
      <path
        fill="#fff"
        d="M22.886 12.639v4.03h15.611v-4.03h-4.21v1.165a.986.986 0 0 1-.987.986h-5.218a.986.986 0 0 1-.986-.986V12.64z"
        transform="translate(-22.225)"
      />
      <path fill="#aaa" d="M7.278 7.583v6.035h2.375V7.583z" />
      <path
        fill="#f0a500"
        d="M5.699.265v.998H4.565V5.28l1.826 2.303h4.148l1.826-2.303V1.263h-1.134V.265z"
      />
      <path d="M5.701 0a.265.265 0 0 0-.265.264v.734h-.87a.265.265 0 0 0-.263.265V5.28a.265.265 0 0 0 .003.028.265.265 0 0 0 .003.025.265.265 0 0 0 .012.04.265.265 0 0 0 .011.025.265.265 0 0 0 .018.03.265.265 0 0 0 .01.017l1.826 2.304a.265.265 0 0 0 .207.1h.623v5.77a.265.265 0 0 0 .264.265h2.374a.265.265 0 0 0 .266-.266v-5.77h.62a.265.265 0 0 0 .208-.1l1.822-2.297a.265.265 0 0 0 .06-.17V1.262a.265.265 0 0 0-.263-.265h-.87V.264A.265.265 0 0 0 11.233 0zm.264.53h5.004v.468H5.965zm-1.133.998h7.271v3.488h-7.27zm.283 4.017h6.705l-1.405 1.773H6.523zm2.428 2.303h1.848V8.9l-1.17.509-.678.294zm-2.808.05a.265.265 0 0 0-.16.053.265.265 0 0 0-.051.371L5.772 9.97a.265.265 0 0 0 .37.052.265.265 0 0 0 .051-.371L4.945 8.002A.265.265 0 0 0 4.77 7.9a.265.265 0 0 0-.035-.002zm7.448 0a.265.265 0 0 0-.193.104l-1.25 1.649a.265.265 0 0 0 .051.37.265.265 0 0 0 .371-.05l1.25-1.649a.265.265 0 0 0-.053-.371.265.265 0 0 0-.176-.052zm-8.991.987a.265.265 0 0 0-.186.082.265.265 0 0 0 .01.375l2.775 2.652a.265.265 0 0 0 .375-.008.265.265 0 0 0-.008-.373L3.381 8.96a.265.265 0 0 0-.19-.074zm10.526 0a.265.265 0 0 0-.165.074l-2.775 2.654a.265.265 0 0 0-.01.373.265.265 0 0 0 .374.008l2.778-2.652a.265.265 0 0 0 .008-.375.265.265 0 0 0-.21-.082zm-4.327.592v1.443l-.656.285-1.192.519V10.28zm0 2.02v1.857H7.543V12.3l.227-.098zm-8.728.878a.265.265 0 0 0-.266.263v4.03a.265.265 0 0 0 .266.266h15.61a.265.265 0 0 0 .265-.266v-4.03a.265.265 0 0 0-.264-.263h-4.211a.265.265 0 0 0-.266.263v1.167c0 .401-.319.72-.72.72h-5.22a.716.716 0 0 1-.72-.72v-1.167a.265.265 0 0 0-.266-.263zm.263.53h3.682v.9c0 .687.562 1.25 1.25 1.25h5.219c.687 0 1.25-.563 1.25-1.25v-.9h3.68v3.5H.927z" />
    </g>
  </svg>
);
export default MillingCncColorfulIcon;
