import { SVGProps } from 'react';

const Model3dColorfulIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 16.933 16.933"
    role="img"
    {...props}>
    <g strokeLinejoin="round" color="#000">
      <path
        fill="#fff"
        d="M6.637 2.907 2.943 4.734v8.239l7.387 3.654v-4.12l3.693-1.827-3.693-1.826-3.693 1.826V6.56l3.693-1.826z"
      />
      <path
        fill="#aaa"
        d="M10.33 4.733 6.637 6.56v4.12l3.693-1.828zm3.693 5.946-3.693 1.827v4.12l3.693-1.827z"
      />
      <path d="M6.637.527a.265.265 0 0 0-.264.264v.529a.265.265 0 0 0 .264.265.265.265 0 0 0 .265-.265V.79a.265.265 0 0 0-.265-.263zm0 1.587a.265.265 0 0 0-.264.265v.365L2.826 4.497a.265.265 0 0 0-.017.012.265.265 0 0 0-.009.005.265.265 0 0 0-.06.053.265.265 0 0 0-.002.004.265.265 0 0 0-.058.16.265.265 0 0 0 0 .003v8.24a.265.265 0 0 0 .146.236l7.387 3.655a.265.265 0 0 0 .056.02.265.265 0 0 0 .008 0 .265.265 0 0 0 .044.006.265.265 0 0 0 .025 0 .265.265 0 0 0 .035-.005.265.265 0 0 0 .02-.005.265.265 0 0 0 .03-.01.265.265 0 0 0 .016-.006l3.694-1.826a.265.265 0 0 0 .146-.238v-4.106a.265.265 0 0 0-.146-.252l-3.547-1.754v-3.94a.265.265 0 0 0-.147-.252L6.902 2.745v-.366a.265.265 0 0 0-.265-.265zm0 1.089L9.73 4.735l-3.094 1.53-3.095-1.53zm3.43 1.957v3.529L7.697 9.86l-.795.393V6.726l1.813-.897zm-6.858.002 3.164 1.564v3.955a.265.265 0 0 0 0 .005.265.265 0 0 0 .006.05.265.265 0 0 0 .003.01.265.265 0 0 0 .003.012.265.265 0 0 0 .015.036.265.265 0 0 0 .002.004.265.265 0 0 0 .006.013.265.265 0 0 0 .007.012.265.265 0 0 0 .016.02.265.265 0 0 0 .015.017.265.265 0 0 0 .004.005.265.265 0 0 0 .012.013.265.265 0 0 0 .01.009.265.265 0 0 0 .008.004.265.265 0 0 0 .04.027l3.546 1.755v3.53L3.21 12.809zM10.33 9.15l3.095 1.53-1.123.556-1.972.977-3.096-1.532zm3.43 1.956v3.53l-3.166 1.566v-3.53zm-11.616 1.99a.265.265 0 0 0-.1.026l-.474.235a.265.265 0 0 0-.12.355.265.265 0 0 0 .354.12l.475-.235a.265.265 0 0 0 .119-.354.265.265 0 0 0-.152-.134.265.265 0 0 0-.102-.013zm-1.414.703a.265.265 0 0 0-.11.028l-.474.235a.265.265 0 0 0-.119.353.265.265 0 0 0 .356.121l.472-.236a.265.265 0 0 0 .121-.353.265.265 0 0 0-.246-.148zm14.05 1.107a.265.265 0 0 0-.245.146.265.265 0 0 0 .12.356l.474.234a.265.265 0 0 0 .353-.12.265.265 0 0 0-.119-.355l-.475-.234a.265.265 0 0 0-.108-.027zm1.423.703a.265.265 0 0 0-.246.148.265.265 0 0 0 .119.354l.475.234a.265.265 0 0 0 .355-.119.265.265 0 0 0-.121-.354l-.472-.236a.265.265 0 0 0-.11-.027z" />
    </g>
  </svg>
);
export default Model3dColorfulIcon;
