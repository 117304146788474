import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import ViktorCncHorizontal from '../../assets/illustrations/ViktorCncHorizontal';
import constants from '../../constants';
import i18next, { languageKeys, languages, TLanguage } from '../../i18n/config';
import CopyToClipBoardButton from '../CopyToClipBoardButton';

const drawerWidth = 240;

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

export default Drawer;
function Drawer(props: Props) {
  const { mobileOpen, handleDrawerToggle } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const container = window !== undefined ? () => window.document.body : undefined;

  const scrollToSection = (id: string) => {
    const anchor = document.getElementById(id);

    if (anchor) {
      window.scrollTo({
        top: anchor.offsetTop - (upSm ? 86 : 50),
      });
    }
  };

  const changeLanguage = async (selectedLanguageKey: TLanguage) => {
    await i18next.changeLanguage(selectedLanguageKey);
    handleDrawerToggle();
  };

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: 'center',
          display: 'flex',
          padding: 1,
          justifyContent: 'center',
        }}>
        <ViktorCncHorizontal
          fixed="height"
          size={32}
          primaryColor={theme.palette.primary.main}
          secondaryColor={theme.palette.grey[900]}
        />
      </Box>

      <Divider />

      <List>
        {constants.sections.map(section => (
          <ListItem key={section.id} disablePadding>
            <ListItemButton
              onClick={() => {
                scrollToSection(section.id);
                handleDrawerToggle();
              }}
              sx={{ textAlign: 'center' }}>
              <ListItemText primary={t(section.titleKey) as string} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider />

      <List>
        <ListItem
          disablePadding
          sx={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>{constants.email}</Typography>
          <CopyToClipBoardButton text={constants.email} />
        </ListItem>

        <ListItem
          disablePadding
          sx={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>{constants.phoneNumber}</Typography>
          <CopyToClipBoardButton text={constants.phoneNumber} />
        </ListItem>
      </List>

      <Divider />

      <List>
        {languageKeys.map(key => (
          <ListItem key={key} disablePadding>
            <ListItemButton onClick={() => changeLanguage(key)} sx={{ textAlign: 'center' }}>
              <ListItemText primary={languages[key].name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box component="nav">
      <MuiDrawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}>
        {drawer}
      </MuiDrawer>
    </Box>
  );
}
