import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CncManLight from '../assets/illustrations/CncManLight';

export default Intro;
function Intro() {
  const { t } = useTranslation();

  const handleClick = () => {
    const anchor = document.getElementById('contact-section');

    if (anchor) {
      window.scrollTo({
        top: anchor.offsetTop - 86,
      });
    }
  };

  return (
    <Box sx={{ paddingTop: { xs: 6, md: 14 }, paddingBottom: { xs: 0, md: 10 } }}>
      <Container style={{ overflow: 'hidden' }}>
        <Grid container>
          <Grid item container xs={12} md={6} sx={{ display: 'flex', flex: '1 1 auto' }}>
            <Grid
              item
              sx={{
                display: 'flex',
                flex: '1 1 auto',
                alignItems: 'center',
              }}>
              <Box
                sx={{
                  flex: '1 1 auto',
                }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: { xs: 34, sm: 40, md: 48 },
                    marginBottom: { xs: 6, md: 2 },
                    textAlign: { xs: 'center', md: 'start' },
                    wordBreak: 'break-word',
                  }}>
                  {t('intro.title')}
                </Typography>

                <Box
                  sx={{
                    display: { xs: 'flex', md: 'none' },
                    flex: '1 1 auto',
                    marginBottom: 3,
                    px: { xs: 0, sm: 6 },
                    pointerEvents: 'none',
                  }}>
                  <CncManLight width="100%" height="100%" />
                </Box>

                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: { xs: 3, md: 4 }, textAlign: { xs: 'center', md: 'start' } }}>
                  {t('intro.subtitle')}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                  <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={handleClick}
                    sx={{ color: '#fff', fontWeight: '600', borderRadius: '100px' }}>
                    {t('contactWithMe')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: 'none', md: 'flex' },
              flex: '1 1 auto',
            }}>
            <Box
              sx={{
                height: '400px',
                width: '100%',
                position: 'relative',
              }}>
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  left: '-10%',
                  width: '120%',
                  pointerEvents: 'none',
                }}>
                <CncManLight width="100%" height="100%" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
