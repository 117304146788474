import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import i18next, { languageKeys, languages, TLanguage } from '../i18n/config';

const LanguageSelect = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = async (selectedLanguageKey: TLanguage) => {
    setAnchorEl(null);
    await i18next.changeLanguage(selectedLanguageKey);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <Button
        size="small"
        color="neutral"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <Typography variant="button">{i18next.language}</Typography>

        <ArrowDropDownRoundedIcon />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {languageKeys.map(languageKey => (
          <MenuItem
            color="neutral"
            selected={languageKey === i18next.language}
            key={languageKey}
            onClick={() => handleClose(languageKey)}>
            {languages[languageKey].name}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default LanguageSelect;
