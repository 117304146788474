import { SVGProps } from 'react';
import useProportionalSvg, { ProportionalSvgProps } from '../../hooks/useProportionalSvg';

const initWidth = 309.87;
const initHeight = 64;

type Props = ProportionalSvgProps & {
  primaryColor?: string;
  secondaryColor?: string;
  svgProps?: SVGProps<SVGSVGElement>;
};

export default ViktorCncHorizontal;
function ViktorCncHorizontal(props: Props) {
  const { fixed, size, primaryColor = '#f0a500', secondaryColor = '#000000', svgProps } = props;
  const { width, height } = useProportionalSvg(fixed, size, initWidth, initHeight);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81.986 16.933"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}>
      <path
        d="M47.201 5.024c-.984 0-1.813.33-2.486.99-.673.654-1.01 1.47-1.01 2.448s.337 1.797 1.01 2.457c.673.654 1.502.982 2.486.982.984 0 1.813-.328 2.486-.982.673-.66 1.01-1.48 1.01-2.457s-.337-1.794-1.01-2.448c-.673-.66-1.502-.99-2.486-.99zm17.288 0c-.978 0-1.807.33-2.486.99-.673.66-1.01 1.486-1.01 2.476 0 .985.33 1.8.99 2.448.667.648 1.512.972 2.534.972 1.029 0 1.922-.426 2.677-1.277l-.953-.98c-.463.577-1.06.866-1.79.866-.534 0-.988-.187-1.363-.561-.374-.375-.561-.87-.561-1.487 0-.622.197-1.114.59-1.476.4-.368.88-.552 1.439-.552.698 0 1.283.292 1.752.876l.924-1.048c-.737-.832-1.65-1.248-2.743-1.248zm14.754 0c-.978 0-1.807.33-2.486.99-.673.66-1.01 1.486-1.01 2.476 0 .985.33 1.8.99 2.448.667.648 1.512.972 2.534.972 1.03 0 1.922-.426 2.677-1.277l-.952-.98c-.464.577-1.061.866-1.791.866-.534 0-.987-.187-1.362-.561-.375-.375-.562-.87-.562-1.487 0-.622.197-1.114.59-1.476.4-.368.88-.552 1.439-.552.698 0 1.283.292 1.753.876l.924-1.048c-.737-.832-1.652-1.248-2.744-1.248zm-58.055.152 2.677 6.658h1.257l2.676-6.658h-1.61l-1.695 4.238-1.695-4.238zm7.401 0v6.658h1.486V5.176zm3.125 0v6.658h1.485V9.948l.667-.743 1.953 2.629h1.733a1313.1 1313.1 0 0 1-2.648-3.715l2.648-2.943h-1.838L33.199 7.91V5.176zm6.21 0v1.286h1.886v5.372h1.485V6.462h1.887V5.176zm14.03 0v6.658h1.486V9.71h1.029l1.476 2.124h1.828l-1.685-2.381c.844-.311 1.266-1 1.266-2.067 0-.775-.222-1.337-.666-1.687-.439-.349-1.175-.523-2.21-.523zm16.412 0v6.658h1.485V7.662l3.172 4.172h1.486V5.176h-1.486v4.286l-3.267-4.286zM47.2 6.319c.558 0 1.028.21 1.41.628s.57.927.57 1.524c0 .59-.19 1.096-.57 1.515a1.845 1.845 0 0 1-1.41.619c-.553 0-1.02-.206-1.4-.62a2.172 2.172 0 0 1-.572-1.514c0-.597.19-1.104.571-1.524s.848-.628 1.4-.628zm6.238.133h1.115c.476 0 .81.067 1 .2.19.134.286.381.286.743 0 .356-.093.616-.277.781s-.53.248-1.038.248H53.44z"
        fill={secondaryColor}
      />
      <path
        d="M8.466 0A8.467 8.467 0 0 0 0 8.467a8.467 8.467 0 0 0 8.466 8.466 8.467 8.467 0 0 0 8.467-8.466A8.467 8.467 0 0 0 8.467 0zm-.793 3.69a.786.786 0 0 1 .252.02c.226.104.182.649.14 1.64-.041.99-.093 1.812-.28 3.222-.177 1.401-.266 2.335-.266 2.802 0 .458.145.687.434.687.29 0 .659-.248 1.107-.743.458-.495.893-1.144 1.304-1.947.41-.813.71-1.967.756-2.34.047-.374-1.373-.595-1.485-.869-.112-.274.247-1.275.518-1.696.218-.32.496-.749.84-.757.18-.002.86.122 1.15.421.3.299.448.654.448 1.065 0 1.018-.303 2.21-.91 3.573-.607 1.364-1.364 2.55-2.27 3.56-.906.999-1.742 1.498-2.508 1.498-.42 0-.776-.173-1.065-.518-.28-.355-.42-.79-.42-1.303s.065-1.42.195-2.719c.131-1.298.197-2.223.197-2.774 0-.55-.001-.774-.116-1.168-.115-.394-.457-.943-.564-1.086-.107-.142-.15-.288-.021-.31.108-.018 1.878-.25 2.564-.259z"
        fill={primaryColor}
      />
    </svg>
  );
}
