import React from 'react';
import About from '../components/About/About';
import ContactSection from '../components/ContactSection';
import Intro from '../components/Intro';
import PortfolioSection from '../components/PortfolioSection';
import ServicesSection from '../components/ServicesSection';
import TopBar from '../components/TopBar/TopBar';

export default RootScreen;
function RootScreen() {
  return (
    <>
      <TopBar />

      <Intro />

      <About />

      <ServicesSection />

      <PortfolioSection />

      <ContactSection />
    </>
  );
}
