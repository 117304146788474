import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import RootScreen from './screens/RootScreen';
import theme from './theme/theme';
import './i18n/config';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />

      <RootScreen />
    </ThemeProvider>
  );
}

export default App;
